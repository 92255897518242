import { create } from 'zustand'

interface IPasswordPolicyState {
  passwordRules: {
    description: string
    regex: RegExp
  }[]
  updatePasswordRules: (
    appScopes: IPasswordPolicyState['passwordRules']
  ) => void
}

export const usePasswordPolicyStore = create<IPasswordPolicyState>()(set => ({
  passwordRules: [],
  updatePasswordRules: passwordRules => set({ passwordRules })
}))
