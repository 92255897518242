import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { statusBadgeStyle } from '$styles/common.css'

const ActiveInactiveStatusBadge = ({
  status = 'inactive'
}: {
  status?: 'active' | 'inactive'
}) => {
  return (
    <span
      className={statusBadgeStyle}
      style={{ textTransform: 'uppercase' }}
      data-active={trueOrUndefined(status === 'active')}
    >
      {status}
    </span>
  )
}

export default ActiveInactiveStatusBadge
