import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { ROUTE_NAMES } from '$router/config'
import Navigate from '$router/Navigate'

const GlobalServiceUserIndexPage = () => {
  const {
    route: { params }
  } = useRouteSummary()

  return (
    <Navigate
      to={ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS}
      options={{ replace: true, params }}
    />
  )
}

export default GlobalServiceUserIndexPage
