import { redirect, ROUTE_NAMES } from '$app/router/config'
import { postLogin, postLogout } from '$app/services/api'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'
import { z } from 'zod'

export const loginSchema = z.object({
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  password: z.string().trim().min(1, 'Requried!')
})

export type TLoginFormValues = z.infer<typeof loginSchema>

const LOGIN_TOAST_ID = 'login-toast-id'
const LOGOUT_TOAST_ID = 'logout-toast-id'

const showLoginToast = (name: string) => {
  toast.dismiss(LOGOUT_TOAST_ID)
  toast.success(`Welcome ${name}!\nYou're all set to explore.`, {
    id: LOGIN_TOAST_ID
  })
}

const showLogoutToast = () => {
  toast.dismiss(LOGIN_TOAST_ID)
  toast.success(`You've logged out!`, { id: LOGOUT_TOAST_ID })
}

export const useLoginLogoutService = () => {
  const { updateToken } = useAuthContext()

  const { loading: loggingIn, run: login } = useRequest(postLogin, {
    manual: true,
    onSuccess: ({ data: { name } }) => {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
      showLoginToast(name)
    },
    onFinally: (_, data) => {
      updateToken(data?.token)
    }
  })

  const { loading: loggingOut, run: logout } = useRequest(postLogout, {
    manual: true,
    onSuccess: () => {
      updateToken(undefined)
      showLogoutToast()
    }
  })

  return {
    loggingIn,
    login,

    loggingOut,
    logout
  }
}
