import { PERMISSIONS } from '$app/constants'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { useCallback } from 'react'

const ROOT = 'root'

export const usePermissions = () => {
  const { user } = useAuthContext()

  const { role, permissions = [] } = user ?? {}

  const checkPermissions = useCallback(
    (perms: PERMISSIONS[]) => {
      const isRoot = role === ROOT

      if (isRoot) return true

      if (!perms.length) return true

      return permissions.some(p => perms.includes(p))
    },
    [role, permissions]
  )

  return {
    checkPermissions
  }
}
