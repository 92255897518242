import { TAppListResponse } from '$services/api'
import AppLogo from '$components/AppLogo'
import { redirect, ROUTE_NAMES } from '$router/config'
import { APP_LAUNCHPAD_URL } from '$services/environments'

import AppEnvironment from '$components/AppEnvironment'
import { MouseEvent, useCallback, useMemo } from 'react'
import {
  Check,
  Copy,
  Folders,
  Link,
  ListTree,
  LucideIcon,
  Users2
} from 'lucide-react'
import { copy, formatNumber, tooltipProps } from '$app/utils'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { themeVars } from '@genie-fintech/ui/style/theme'
import {
  Body,
  Container,
  CopyItemContainer,
  CopyItemStyle,
  Footer,
  FooterIconStyle,
  FooterItemStyle
} from './styles.css'
import { useBoolean } from 'ahooks'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars
interface IAppCardProps {
  data: TAppListResponse['data'][number]
}

const CopyItem = ({
  label,
  value,
  Icon: _Icon
}: {
  label: string
  value: string
  Icon: LucideIcon
}) => {
  const [isCopied, { setTrue, setFalse }] = useBoolean(false)

  const iconColor = useMemo(
    () => (isCopied ? colors.success[markedDefaultKey] : colors.text.disabled),
    [isCopied]
  )

  const Icon = useMemo(() => (isCopied ? Check : _Icon), [isCopied, _Icon])

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      copy(value, setTrue)
    },
    [value, setTrue]
  )

  const onBlur = useCallback(() => {
    setFalse()
  }, [setFalse])

  return (
    <button
      type="button"
      onClick={onClick}
      onBlur={onBlur}
      className={CopyItemStyle}
      {...tooltipProps(isCopied ? 'Copied' : 'Copy')}
    >
      {label}

      <Icon size={12} style={{ color: iconColor }} />
    </button>
  )
}

const FooterItem = ({
  label,
  value,
  Icon
}: {
  label: string
  value: number
  Icon: LucideIcon
}) => {
  return (
    <button className={FooterItemStyle} {...tooltipProps(label)}>
      <Icon size={16} className={FooterIconStyle} />

      {formatNumber(value)}
    </button>
  )
}

const AppCard = ({ data }: IAppCardProps) => {
  const {
    id: appId,
    logo,
    brand,
    is_published,
    name,
    environment,
    client_id,
    user_count,
    group_count,
    role_count
  } = data

  const isMounted = useIsMounted()

  const handleOnClickCard = useCallback(() => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [appId])

  return (
    <article
      onClick={handleOnClickCard}
      className={Container}
      data-ready={trueOrUndefined(isMounted)}
    >
      <article className={Body}>
        <article className="flex items-center gap-3">
          <AppLogo
            imgUrl={logo?.url}
            brand={brand}
            size="big"
            isPublished={is_published}
          />
          <article className="flex flex-col gap-1 min-w-0">
            <p className="text-sm font-medium truncate">{name}</p>
            <AppEnvironment environment={environment} />
          </article>
        </article>

        <article className={CopyItemContainer}>
          <CopyItem label="Client ID" value={client_id} Icon={Copy} />

          <CopyItem label="URL" value={APP_LAUNCHPAD_URL} Icon={Link} />
        </article>
      </article>

      <article className={Footer}>
        <FooterItem label="Users" value={user_count} Icon={Users2} />

        <FooterItem label="Roles" value={role_count} Icon={ListTree} />

        <FooterItem label="Groups" value={group_count} Icon={Folders} />
      </article>
    </article>
  )
}

export default AppCard
