import * as Dialog from '@radix-ui/react-dialog'
import { AlertTriangle } from 'lucide-react'
import CloseButton from '$components/CloseButton'
import { useCallback } from 'react'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

interface IProps {
  title?: string
  desc?: string
  open?: boolean
  onClose?: VoidFunction
  onConfirm?: VoidFunction
}

const PopupModal = ({
  open,
  title = 'Are you sure you want to proceed this action?',
  onConfirm,
  onClose,
  desc = ''
}: IProps) => {
  const onProceed = useCallback(() => {
    onConfirm?.()
    onClose?.()
  }, [onClose, onConfirm])

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col gap-4 max-w-[480px] p-8"
          aria-describedby=""
        >
          <Dialog.Title className="hidden" />

          <article className="flex items-center justify-between gap-2">
            <article className="inline-flex border border-[--colors-warning-20] text-[--colors-warning-default] bg-[--colors-alphaWarning-1] rounded-lg p-2.5">
              <AlertTriangle />
            </article>

            <CloseButton onClick={onClose} />
          </article>

          <p className="text-xl font-semibold">{title}</p>

          {desc && (
            <p className="text-sm" style={{ color: colors.neutral[70] }}>
              {desc}
            </p>
          )}

          <article className="flex items-center justify-end gap-2">
            <Button
              styleVariants={{ kind: 'neutral', type: 'outlined' }}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button autoFocus onClick={onProceed}>
              Confirm
            </Button>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PopupModal
