import { cn, formatTime } from '$app/utils'
import CopyButton from '$components/CopyButon/v2'
import { useGlobalServiceUserService } from '$hooks/services'
import { TData_OTP } from '$services/api'
import { description, ready } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import { Namespaces } from '@genie-fintech/ui/icons/types'
import { status, StatusVariants } from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useCountDown } from 'ahooks'
import { Clock } from 'lucide-react'
import { useCallback, useMemo } from 'react'

const { colors } = themeVars

const OTPData = ({
  data,
  callbackFn
}: {
  data: TData_OTP
  callbackFn: VoidFunction
}) => {
  const isMounted = useIsMounted()

  const isValidOTP = data.code && data.expires_in && !data.is_verified

  const isOTPExpired = data.code && !data.expires_in && !data.is_verified

  const isOTPNotFound = !!data.message || data.is_verified

  const targetDate = useMemo(() => {
    if (isOTPNotFound) return undefined

    return data.expires_in ? Date.now() + data.expires_in * 1000 : undefined
  }, [isOTPNotFound, data.expires_in])

  const [, { seconds, minutes }] = useCountDown({
    targetDate,
    onEnd: () => {
      callbackFn()
    }
  })

  const statusKind: StatusVariants['kind'] = (() => {
    if (isValidOTP) return 'success'
    if (isOTPExpired) return 'danger'
    return 'grey'
  })()

  const iconName: Namespaces = (() => {
    if (isValidOTP) return 'Check'
    return 'Warning'
  })()

  const statusText = (() => {
    if (isValidOTP) return 'Requested OTP code is here!'
    if (isOTPExpired) return 'Requested OTP code is expired!'
    return 'No requested OTP is found!'
  })()

  return (
    <article
      className={cn('flex flex-col gap-2', ready)}
      data-ready={trueOrUndefined(isMounted)}
    >
      {!isOTPNotFound && (
        <article
          className="flex p-4 items-center justify-between rounded border"
          style={{ borderColor: colors.neutral[10] }}
        >
          <Button
            styleVariants={{ type: 'text', kind: 'neutral', size: 'small' }}
            className="pointer-events-none"
          >
            <Clock size={16} />
            <span>
              {formatTime(minutes)}:{formatTime(seconds)} min
            </span>
          </Button>

          <article className="inline-flex gap-3">
            <h3 className={title.one}>{data.code}</h3>
            <CopyButton size={20} color="primary.default" value={data.code} />
          </article>
        </article>
      )}

      <article className="flex justify-end">
        <span
          className={status({
            kind: statusKind,
            style: 'rounded',
            type: 'faint',
            material: 'icon'
          })}
        >
          <Icon namespace={iconName} />
          {statusText}
        </span>
      </article>
    </article>
  )
}

const OTPSetting = ({ userId }: { userId: string | undefined }) => {
  const { fetchCurrentOTP, fetchingCurrentOTP, currentOTP } =
    useGlobalServiceUserService()

  const onGetCurrentOTP = useCallback(() => {
    if (!userId) return
    fetchCurrentOTP({ userId })
  }, [userId, fetchCurrentOTP])

  return (
    <article className="grid grid-cols-1 lg:grid-cols-2 gap-16">
      <article className="flex flex-col gap-3">
        <article className="flex flex-col gap-1">
          <h4 className={title.two}>REQUESTED 2FA OTP</h4>
          <p className={description}>
            This OTP can use only once on requested situation and it has time
            interval showing accordingly.
          </p>
        </article>

        <p
          className={footnote.three}
          style={{ color: colors.warning[markedDefaultKey] }}
        >
          You can not share anyone except the email owner.
        </p>
      </article>

      <article className="flex flex-col gap-4">
        {currentOTP && !fetchingCurrentOTP && (
          <OTPData data={currentOTP} callbackFn={onGetCurrentOTP} />
        )}

        <article className="flex justify-end">
          <Button
            styleVariants={{ size: 'small' }}
            disabled={fetchingCurrentOTP}
            onClick={onGetCurrentOTP}
          >
            {fetchingCurrentOTP && <Spinner />}
            Check Requested OTP
          </Button>
        </article>
      </article>
    </article>
  )
}

export default OTPSetting
