import { usePasswordPolicyStore } from '$hooks/stores/usePasswordPolicyStore'
import { Icon } from '@genie-fintech/ui/icons'
import { container, icon, text } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const PasswordPolicies = ({ password }: { password: string }) => {
  const passwordRules = usePasswordPolicyStore(state => state.passwordRules)

  return (
    <article className="flex flex-col gap-2">
      {passwordRules.map((rule, idx) => {
        const isPass = !!password && rule.regex.test(password)
        return (
          <article
            key={idx}
            className={container}
            data-active={trueOrUndefined(isPass)}
          >
            <Icon
              namespace={isPass ? 'TickCircle' : 'Warning'}
              width={18}
              className={icon}
            />

            <span className={text}> {rule.description} </span>
          </article>
        )
      })}
    </article>
  )
}

export default PasswordPolicies
