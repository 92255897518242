import { cn } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { useCountryStore } from '$hooks/stores'
import { useShallow } from 'zustand/react/shallow'
import Loading from '$components/Loading'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import FooterAction from '$components/FooterAction'
import RedirectPrompt from '$blocks/RedirectPrompt'
import { useCallback } from 'react'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService } from '$hooks/services'
import { TAppGroupPayload, TID } from '$services/api'

const { colors } = themeVars

const AppGroupCreate = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const { countryOptions, phoneOptions } = useCountryStore(
    useShallow(state => ({
      countryOptions: state.countryOptions,
      phoneOptions: state.phoneOptions
    }))
  )

  const { saveGroupAsync, savingGroup } = useAppGroupService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = methods

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_GROUPS, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(
    (groupId: TID) => {
      redirect(ROUTE_NAMES.APP_GROUP_DETAIL, { params: { ...params, groupId } })
    },
    [params]
  )

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const { country, phone_code, ...rest } = formValues

    const payload: TAppGroupPayload = {
      country_id: +country,
      phone_code: phone_code.value,
      ...rest
    }

    return saveGroupAsync(appId, payload).then(({ data }) => {
      onSuccess(data.id)
    })
  })

  if (!countryOptions.length) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: `App Details`, path: ROUTE_NAMES.APP_GROUPS },
          { name: `Add Group` }
        ]}
      />

      <form
        className="flex flex-col mx-auto max-w-[1056px] gap-2"
        onSubmit={onSubmit}
      >
        <article
          className={cn(
            'flex flex-col rounded-lg',
            defaultBackground,
            defaultBorder
          )}
        >
          <header
            className="flex items-center px-12 py-3 border-b"
            style={{ borderColor: colors.neutral[10] }}
          >
            <p
              className="text-xl font-semibold"
              style={{ color: colors.text.light }}
            >
              Add New Group
            </p>
          </header>

          <article className="flex px-12 py-7">
            <article className="flex flex-col gap-6">
              <article className="flex flex-col gap-1">
                <p className="font-semibold">COUNTRY/REGION</p>
                <p className="text-xs" style={{ color: colors.neutral[50] }}>
                  Specify country or region to tailor settings and services
                  accordingly.
                </p>
              </article>

              <Controller
                name="country"
                control={control}
                render={({ field }) => {
                  return (
                    <Tabs.Root
                      value={field.value}
                      onValueChange={field.onChange}
                      format={{ type: 'segmented' }}
                    >
                      <Tabs.List styleVariants={{ hAlign: 'left' }}>
                        {countryOptions.map((v, k) => (
                          <Tabs.Trigger key={k} value={`${v.value}`}>
                            {v.label}
                          </Tabs.Trigger>
                        ))}
                      </Tabs.List>
                    </Tabs.Root>
                  )
                }}
              />
            </article>
          </article>
        </article>

        <article
          className={cn(
            'flex flex-col rounded-lg px-12 py-7',
            defaultBackground,
            defaultBorder
          )}
        >
          <article className="grid lg:grid-cols-2 gap-8">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">GROUP INFO</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                View detailed information about each group to manage roles and
                permissions effectively.
              </p>
            </article>

            <article className="flex flex-col gap-5">
              <BaseText
                control={control}
                name="name"
                label="Group Name"
                required
              />

              <article className="relative">
                <BaseText
                  control={control}
                  name="phone_no"
                  label="Phone Number"
                  inputProps={{ className: 'pl-[100px]' }}
                />
                <article className="absolute bottom-0 left-0 w-[100px]">
                  <Controller
                    name="phone_code"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          value={field.value}
                          onChange={field.onChange}
                          options={phoneOptions}
                          type="sub"
                        />
                      )
                    }}
                  />
                </article>
              </article>

              <Textarea
                name="description"
                control={control}
                label="Description"
              />
            </article>
          </article>
        </article>

        <FooterAction isFullPage>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={savingGroup}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!isDirty || savingGroup}>
              {savingGroup && <Spinner />}
              Create
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt isDirty={isDirty} type="create" />
    </>
  )
}

export default AppGroupCreate
