import { tooltipProps } from '$app/utils'
import { useCopy } from '$hooks/actions'
import { toClassNames, trueOrUndefined } from '@genie-fintech/ui/functions'
import { ColorVariant } from '@genie-fintech/ui/style/atoms'
import { copyButton } from './styles.css'
import { Copy, CopyCheck } from 'lucide-react'
import { colorPaths } from '@genie-fintech/ui/style/paths'

interface IProps {
  size?: number
  color?: keyof ColorVariant
  value?: string
}

const colorPalette = {
  inherit: 'inherit',
  ...colorPaths
}

const CopyButton = ({ value = '', size = 20, color = 'inherit' }: IProps) => {
  const { isCopied, onCopy, onBlur } = useCopy(value)

  const Icon = isCopied ? CopyCheck : Copy

  return (
    <button
      type="button"
      onClick={onCopy}
      onBlur={onBlur}
      className={toClassNames(copyButton)}
      style={{ color: colorPalette[color] }}
      data-active={trueOrUndefined(isCopied)}
      {...tooltipProps(isCopied ? 'Copied' : 'Copy')}
    >
      <Icon size={size} />
    </button>
  )
}

export default CopyButton
