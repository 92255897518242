import { TGlobalServiceUserInfo } from '$services/api'
import { create } from 'zustand'

interface IGlobalServiceUserInfoState {
  userInfo?: TGlobalServiceUserInfo
  updateUserInfo: (userInfo: IGlobalServiceUserInfoState['userInfo']) => void
}

export const useUserInfoStore = create<IGlobalServiceUserInfoState>()(set => ({
  updateUserInfo: userInfo => set({ userInfo })
}))
