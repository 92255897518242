import { ready } from '$styles/common.css'
import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { FC, PropsWithChildren } from 'react'

const EnterTransitionBlock: FC<PropsWithChildren<TagsNoRef<'article'>>> = ({
  children,
  ...props
}) => {
  const isMounted = useIsMounted()

  return (
    <article
      {...propsWithClassNames(props, ready)}
      data-ready={trueOrUndefined(isMounted)}
    >
      {children}
    </article>
  )
}

export default EnterTransitionBlock
