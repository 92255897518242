import { Icon } from '@genie-fintech/ui/icons'
import { container, icon, text } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const PasswordStateMessage = ({
  message,
  isPass
}: {
  message: string
  isPass: boolean
}) => {
  return (
    <article className={container} data-active={trueOrUndefined(isPass)}>
      <Icon
        namespace={isPass ? 'TickCircle' : 'Warning'}
        width={18}
        className={icon}
      />

      <span className={text}> {message} </span>
    </article>
  )
}

export default PasswordStateMessage
