import { cn } from '$app/utils'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { usePasswordPolicyService } from '$hooks/services'
import { Checkbox } from '@genie-fintech/ui/components'
import { useMount } from 'ahooks'
import { Key } from 'lucide-react'
import React, { FormEvent, useCallback, useRef, useState } from 'react'
import {
  LOWERCASE_UPPERCASE_NUMBER_REGEX,
  MIN_LENGTH_ID,
  PASSWORD_POLICY_CONFIG,
  SPECIAL_LETTER_REGEX,
  TWO_IDENTICAL_ROW_REGEX
} from './constants'
import { BaseText, Password } from '@genie-fintech/ui/components/fields'
import { toast } from 'sonner'
import { TPasswordPolicyPayload } from '$services/api'
import PasswordStateMessage from '$components/PasswordStateMessage'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'
import FooterAction from '$components/FooterAction'
import { MAX_PWD_LENGTH, MIN_PWD_LENGTH } from '$app/constants'

enum PASSWORD_STRENGTH {
  WEAK = 'weak',
  GOOD = 'good',
  STRONG = 'strong'
}

const classNames: Record<
  PASSWORD_STRENGTH | 'inactive',
  { foreground: string; background: string; indicator: string }
> = {
  [PASSWORD_STRENGTH.WEAK]: {
    foreground: 'text-[--colors-warning-default]',
    background: 'bg-[--colors-alphaWarning-0]',
    indicator: 'bg-[--colors-warning-default]'
  },
  [PASSWORD_STRENGTH.GOOD]: {
    foreground: 'text-[--colors-primary-default]',
    background: 'bg-[--colors-alphaPrimary-0]',
    indicator: 'bg-[--colors-primary-default]'
  },
  [PASSWORD_STRENGTH.STRONG]: {
    foreground: 'text-[--colors-success-default]',
    background: 'bg-[--colors-alphaSuccess-0]',
    indicator: 'bg-[--colors-success-default]'
  },
  inactive: {
    foreground: 'text-[--colors-text-disabled]',
    background: 'bg-[--colors-neutral-10]',
    indicator: 'bg-[--colors-text-disabled]'
  }
}

const PasswordStrength = ({
  type,
  active
}: {
  type: PASSWORD_STRENGTH
  active: boolean
}) => {
  const { background, foreground, indicator } = active
    ? classNames[type]
    : classNames.inactive

  return (
    <article className="flex items-center gap-2">
      <span
        className={cn(
          'inline-flex w-6 h-6 items-center justify-center rounded-full',
          background
        )}
      >
        <span className={cn('inline-flex w-2 h-2 rounded-full', indicator)} />
      </span>

      <span className={cn('font-medium text-sm capitalize', foreground)}>
        {type}
      </span>
    </article>
  )
}

const PasswordPolicy = () => {
  const {
    fetchPasswordPolicyAsync,
    fetchingPasswordPolicy,
    passwordPolicy,
    updatePasswordPolicy,
    updatingPasswordPolicy
  } = usePasswordPolicyService()

  const [checkedPolicy, setCheckedPolicy] = useState<number[]>([])

  const [minLength, setMinLength] = useState<number>()

  const [isMinLengthInvalid, setIsMinLengthInvalid] = useState(false)

  const [testPwd, setTestPwd] = useState('')

  const minLengthRef = useRef<HTMLInputElement | null>(null)

  useMount(() => {
    fetchPasswordPolicyAsync().then(({ data }) => {
      setCheckedPolicy(data.filter(v => v.is_enabled).map(v => v.id))
      const { min_length } = data.find(d => d.id === MIN_LENGTH_ID) ?? {}
      setMinLength(min_length ?? undefined)
    })
  })

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.currentTarget.value.trim()
      const onlyDigitRegex = /^\d*$/
      if (onlyDigitRegex.test(inputValue)) {
        setMinLength(inputValue.length ? +inputValue : undefined)
        setIsMinLengthInvalid(false)
      }
    },
    []
  )

  const validateInput = useCallback(() => {
    const isMinLenghtPolicyChecked = checkedPolicy.includes(MIN_LENGTH_ID)

    if (!isMinLenghtPolicyChecked) return true

    if (!minLength || minLength < MIN_PWD_LENGTH) {
      toast.error(`The password length must be ${MIN_PWD_LENGTH} at least!`)
      return false
    }

    if (minLength > MAX_PWD_LENGTH) {
      toast.error(`The password length must be ${MAX_PWD_LENGTH} at most!`)
      return false
    }

    return true
  }, [checkedPolicy, minLength])

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (!validateInput()) {
        minLengthRef.current?.focus()
        setIsMinLengthInvalid(true)
        return
      }

      const policies = passwordPolicy.reduce((r, c) => {
        const is_enabled = checkedPolicy.includes(c.id)
        const policy: TPasswordPolicyPayload = {
          name: c.name,
          is_enabled,
          min_length: c.id === MIN_LENGTH_ID && minLength ? +minLength : null
        }
        return [...r, policy]
      }, [] as TPasswordPolicyPayload[])

      updatePasswordPolicy({ policies })
    },
    [
      validateInput,
      checkedPolicy,
      minLength,
      passwordPolicy,
      updatePasswordPolicy
    ]
  )

  const isProcessing = fetchingPasswordPolicy || updatingPasswordPolicy

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.GLOBAL_SETTING} />

      <article>
        <form
          className="flex-1 flex flex-col gap-2 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg overflow-hidden"
          onSubmit={onSubmit}
        >
          <header className="flex items-center justify-between h-[56px] px-3 bg-[--colors-area-high] border-b border-[--colors-neutral-10]">
            <article className="flex items-center gap-x-2 text-[--colors-text-light]">
              <Key />
              <p className="text-xl font-semibold">Password Policy</p>
            </article>
          </header>

          <main className="grid p-4 lg:grid-cols-[50%_50%] gap-4">
            <article className="flex flex-col gap-y-1">
              <p className="text-xl font-semibold">PASSWORD STRENGTH</p>
              <p className="text-xs text-[--colors-neutral-50]">
                Password strength is an important consideration when using
                passwords for Carro SSO service. A strong password policy will
                make it difficult, if not improbable, for someone to guess a
                password through either manual or automated means.
              </p>
            </article>

            <article className="flex-1 flex flex-col gap-y-4">
              <p className="font-medium text-sm">Strength:</p>

              <article className="flex items-center gap-4">
                <PasswordStrength
                  type={PASSWORD_STRENGTH.WEAK}
                  active={checkedPolicy.length < 3}
                />

                <PasswordStrength
                  type={PASSWORD_STRENGTH.GOOD}
                  active={
                    checkedPolicy.length >= 3 &&
                    checkedPolicy.length < passwordPolicy.length
                  }
                />

                <PasswordStrength
                  type={PASSWORD_STRENGTH.STRONG}
                  active={checkedPolicy.length === passwordPolicy.length}
                />
              </article>

              <article className="flex flex-col p-4 gap-6 bg-[--colors-alphaArea-disabled] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg">
                {passwordPolicy.map((v, k) => {
                  const { label = '' } =
                    PASSWORD_POLICY_CONFIG.find(d => d.id === v.id) ?? {}
                  return (
                    <article key={k} className="flex items-center gap-2">
                      <Checkbox
                        key={k}
                        boxProps={{
                          checked: checkedPolicy.includes(v.id),
                          onCheckedChange: checked => {
                            setCheckedPolicy(prev =>
                              checked
                                ? [...prev, v.id]
                                : prev.filter(d => d !== v.id)
                            )

                            if (v.id === MIN_LENGTH_ID) {
                              setMinLength(undefined)
                            }
                          }
                        }}
                      />

                      <article>
                        {(() => {
                          if (v.id === MIN_LENGTH_ID) {
                            const [first, second] = label
                              .split('###')
                              .map(v => v.trim())

                            return (
                              <article className="flex items-center gap-1 text-sm font-medium text-[--colors-text-light]">
                                <span>{first}</span>
                                <span>
                                  <BaseText
                                    inputRef={minLengthRef}
                                    inputProps={{
                                      type: 'text',
                                      autoComplete: '',
                                      value: minLength ?? '',
                                      onChange: onInputChange,
                                      className: 'max-w-[50px]'
                                    }}
                                    disabled={
                                      !checkedPolicy.includes(MIN_LENGTH_ID)
                                    }
                                    error={isMinLengthInvalid}
                                  />
                                </span>
                                <span>{second}</span>
                              </article>
                            )
                          }

                          return (
                            <p className="text-sm font-medium text-[--colors-text-light]">
                              {label}
                            </p>
                          )
                        })()}
                      </article>
                    </article>
                  )
                })}
              </article>

              <article className="flex flex-col p-4 gap-4 bg-[--colors-alphaArea-disabled] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg">
                <p className="text-sm font-semibold">PREVIEW</p>

                <article className="flex flex-col gap-2 px-5">
                  <Password
                    label="Test Password"
                    required
                    inputProps={{
                      autoComplete: '',
                      value: testPwd,
                      onChange: e => setTestPwd(e.currentTarget.value)
                    }}
                  />
                </article>

                <article className="flex flex-col gap-1 px-5">
                  {passwordPolicy
                    .filter(d => checkedPolicy.includes(d.id))
                    .map(({ min_length, id }, k) => {
                      const msg = (() => {
                        let { label = '' } =
                          PASSWORD_POLICY_CONFIG.find(d => d.id === id) ?? {}

                        if (id === MIN_LENGTH_ID) {
                          label = label.replace('###', `${minLength ?? '0'}`)
                        }

                        return label
                      })()

                      const isPass = (() => {
                        switch (id) {
                          case 1:
                            return (
                              !!testPwd.length &&
                              TWO_IDENTICAL_ROW_REGEX.test(testPwd)
                            )
                          case 2:
                            return SPECIAL_LETTER_REGEX.test(testPwd)
                          case 3:
                            return LOWERCASE_UPPERCASE_NUMBER_REGEX.test(
                              testPwd
                            )
                          case 4:
                            return !!min_length && testPwd.length >= +min_length
                          case 5:
                            return !!testPwd.length
                          default:
                            return false
                        }
                      })()

                      return (
                        <PasswordStateMessage
                          key={k}
                          message={msg}
                          isPass={isPass}
                        />
                      )
                    })}
                </article>
              </article>
            </article>
          </main>

          <FooterAction isFullPage={false}>
            <SaveWithShortCutButton
              disabled={isProcessing}
              loading={isProcessing}
            />
          </FooterAction>
        </form>
      </article>
    </>
  )
}

export default PasswordPolicy
