import EmptyData from '$components/EmptyData'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'

const { colors } = themeVars

const GlobalServiceUserDevicesLogin = () => {
  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title="MANAGE ALL DEVICES"
        desc="Manage and review user's login devices for efficient system administration."
      />
      <article className={mainSubContainerStyle}>
        <p className={title.six} style={{ color: colors.text.light }}>
          These are the devices that user logged in. There might be multiple
          activity sessions from the same devices.
        </p>

        <article
          className="flex flex-col p-20 border rounded"
          style={{ borderColor: colors.neutral[10] }}
        >
          <EmptyData type="devices" />
        </article>
      </article>
    </EnterTransitionBlock>
  )
}

export default GlobalServiceUserDevicesLogin
