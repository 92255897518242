import {
  deleteResetPasswordLink,
  getAppUserDetail,
  getAppUserList,
  postAppUser,
  postAttachRoleSToUsers,
  postResetPasswordLink,
  putAppUser
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppUserService = () => {
  const {
    run: fetchAppUserList,
    runAsync: fetchAppUserListAsync,
    data: AppUserListData,
    loading: fetchingAppUserList
  } = useRequest(getAppUserList, {
    manual: true
  })

  const {
    run: fetchAppUserDetail,
    runAsync: fetchAppUserDetailAsync,
    data: appUserDatail,
    loading: fetchingAppUserDetail
  } = useRequest(getAppUserDetail, {
    manual: true
  })

  const { runAsync: saveUserAsync, loading: savingUser } = useRequest(
    postAppUser,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
      }
    }
  )

  const { runAsync: updateUserAsync, loading: updatingUser } = useRequest(
    putAppUser,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updateed!')
      }
    }
  )

  const { runAsync: attachRoleToUserAsync, loading: attachingRoleToUser } =
    useRequest(postAttachRoleSToUsers, {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
      }
    })

  const { runAsync: createResetPwdLinkAsync, loading: creatingResetPwdLink } =
    useRequest(postResetPasswordLink, { manual: true })

  const { runAsync: removeResetPwdLinkAsync, loading: removingResetPwdLink } =
    useRequest(deleteResetPasswordLink, { manual: true })

  const { data: list = [], meta } = AppUserListData ?? {}

  return {
    users: { list, meta },
    fetchAppUserList,
    fetchingAppUserList,
    fetchAppUserListAsync,

    user: appUserDatail?.data,
    fetchingAppUserDetail,
    fetchAppUserDetail,
    fetchAppUserDetailAsync,

    saveUserAsync,
    savingUser,

    updateUserAsync,
    updatingUser,

    attachRoleToUserAsync,
    attachingRoleToUser,

    createResetPwdLinkAsync,
    creatingResetPwdLink,

    removeResetPwdLinkAsync,
    removingResetPwdLink
  }
}
