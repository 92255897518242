import { ButtonProps } from '@genie-fintech/ui/components'
import { z } from 'zod'

const trimmedString = z.string().trim()

export const schema = z.object({
  name: trimmedString.min(1, 'Required!'),
  email: trimmedString,
  phone_no: trimmedString,
  phone_code: z.object({
    value: trimmedString,
    label: trimmedString
  }),
  date_of_birth: trimmedString,
  gender: z.object({
    value: trimmedString,
    label: trimmedString
  }),
  address: trimmedString
})

export type TFormValues = z.infer<typeof schema>

export const INITIAL_DEFAULT_VALUES: TFormValues = {
  name: '',
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: '',
  date_of_birth: '',
  gender: { label: '', value: '' },
  address: ''
}

export const outlineNeutralButtonVariant: ButtonProps['styleVariants'] = {
  size: 'small',
  type: 'outlined',
  kind: 'neutral'
}

export enum LABEL {
  USERNAME = 'User Name',
  EMAIL = 'Email Address',
  PHONE = 'Phone Number',
  DOB = 'Date of Birth',
  GENDER = 'Gender',
  ADDRESS = 'Address'
}
