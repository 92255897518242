import { themeVars } from '@genie-fintech/ui/style/theme'
import { body, title as titleStyle } from '@genie-fintech/ui/style/typography'

const { colors } = themeVars

const MainHeader = ({ title, desc }: { title: string; desc: string }) => {
  return (
    <article
      className="flex flex-col gap-1"
      style={{ color: colors.absolute.light }}
    >
      <p className={titleStyle.one}>{title}</p>
      <p className={body.three}>{desc}</p>
    </article>
  )
}

export default MainHeader
