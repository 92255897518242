import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { MainRedirectOptions } from 'rutter'

const Navigate = ({
  to,
  options
}: {
  to: ROUTE_NAMES
  options?: MainRedirectOptions
}) => {
  useMount(() => {
    redirect(to, options)
    return
  })

  return null
}

export default Navigate
