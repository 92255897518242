import { z } from 'zod'

export const schema = z
  .object({
    logout_daily: z.boolean(),
    has_password_history_depth: z.boolean(),
    password_history_depth: z.string().trim(),
    should_reset_password: z.boolean(),
    has_password_expiration_days: z.boolean(),
    password_expiration_days: z.string().trim()
  })
  .superRefine((val, ctx) => {
    if (val.has_password_history_depth && +val.password_history_depth < 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'must be greater than 0',
        path: ['password_history_depth']
      })
    }

    if (val.has_password_expiration_days && +val.password_expiration_days < 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'must be greater than 0',
        path: ['password_expiration_days']
      })
    }
  })

export type TFormValues = z.infer<typeof schema>
