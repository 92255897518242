import { usePasswordPolicyStore } from '$hooks/stores/usePasswordPolicyStore'
import {
  getPasswordPolicy,
  getPasswordPolicySetting,
  putPasswordPolicy
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'
import RegexParser from 'regex-parser'

export const usePasswordPolicyService = () => {
  const updatePasswordRules = usePasswordPolicyStore(
    state => state.updatePasswordRules
  )

  const {
    run: fetchPasswordPolicy,
    runAsync: fetchPasswordPolicyAsync,
    loading: fetchingPasswordPolicy,
    data: passwordPolicyData
  } = useRequest(getPasswordPolicy, {
    manual: true
  })

  const {
    run: fetchPasswordPolicySetting,
    loading: fetchingPasswordPolicySetting
  } = useRequest(getPasswordPolicySetting, {
    manual: true,
    onSuccess: ({ data: { password_rules } }) => {
      updatePasswordRules(
        password_rules.map(({ regex, ...rest }) => ({
          ...rest,
          regex: RegexParser(regex)
        }))
      )
    }
  })

  const { run: updatePasswordPolicy, loading: updatingPasswordPolicy } =
    useRequest(putPasswordPolicy, {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
        fetchPasswordPolicy()
        fetchPasswordPolicySetting()
      }
    })

  const { data: passwordPolicy = [] } = passwordPolicyData ?? {}

  return {
    fetchPasswordPolicy,
    fetchPasswordPolicyAsync,
    fetchingPasswordPolicy,
    passwordPolicy,

    updatePasswordPolicy,
    updatingPasswordPolicy,

    fetchPasswordPolicySetting,
    fetchingPasswordPolicySetting
  }
}
