import { Api, errorMessageResolver, errorStatusResolver } from '$services/api'
import { useEffect } from 'react'
import { toast } from 'sonner'

export const useAxiosInterceptors = (unauthorizedCallbackFn?: VoidFunction) => {
  useEffect(() => {
    Api.interceptors.response.use(
      response => response,
      error => {
        const message = errorMessageResolver(error)

        const status = errorStatusResolver(error)

        toast.error(message, { id: 'error-toast' })

        if (status === 401) {
          unauthorizedCallbackFn?.()
          return
        }

        return Promise.reject(error)
      }
    )
  }, [unauthorizedCallbackFn])
}
