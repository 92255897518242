import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import { title } from '@genie-fintech/ui/style/typography'
import { appCard } from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button } from '@genie-fintech/ui/components'
import { Plus } from 'lucide-react'
import AppLogo from '$components/AppLogo'
import { useCallback } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import { useGlobalServiceUserService } from '$hooks/services'
import { useUserId } from '$layouts/GlobalUserLayout/hooks/useUserId'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'
import { useMount } from 'ahooks'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'

const { colors } = themeVars

const GlobalServiceUserAccessApps = ({ userType }: { userType: USER_TYPE }) => {
  const { userId } = useUserId(userType)

  const { fetchAccessApps, fetchingAccessApps, accessApps } =
    useGlobalServiceUserService()

  useMount(() => {
    if (!userId) return
    fetchAccessApps({ userId })
  })

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACH_APP, {
      params: { serviceUserId: userId }
    })
  }, [userId])

  if (fetchingAccessApps) return <Loading />

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title={`ACCESS ${accessApps.length} APPLICATIONS TO USE`}
        desc="This user can access these applications by related assigned roles and groups."
      />

      <article className={mainSubContainerStyle}>
        <article className="flex items-center justify-between gap-1">
          <p className={title.six} style={{ color: colors.text.light }}>
            You can add more application to this user.
          </p>

          <Button styleVariants={{ size: 'small' }} onClick={onAddApplication}>
            <Plus size={16} />
            Add Application
          </Button>
        </article>

        {!accessApps.length && !fetchingAccessApps && (
          <EmptyData type="applications" />
        )}

        <article className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2">
          {accessApps.map(v => {
            return (
              <button
                key={v.id}
                type="button"
                className={appCard}
                onClick={() => onClickAppCard(v.id)}
              >
                <AppLogo imgUrl={v.logo?.url} brand={v.brand} size="big" />
                <article className="flex flex-col gap-1">
                  <p className={title.six}>{v.name}</p>
                </article>
              </button>
            )
          })}
        </article>
      </article>
    </EnterTransitionBlock>
  )
}

export default GlobalServiceUserAccessApps
