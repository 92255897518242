import LayoutWithBackground from '$layouts/LayoutWithBackground'
import { FC, PropsWithChildren } from 'react'
import { MainContainerStyle, SidebarStyle } from './styles.css'
import { CommonContainerStyle, description } from '$styles/common.css'
import { USER_TYPE } from './types'
import { useUserId } from './hooks/useUserId'
import { useGlobalServiceUserService } from '$hooks/services'
import { useMount } from 'ahooks'
import Loading from '$components/Loading'
import ProfileInfo from './components/ProfileInfo'
import { useUserInfoStore } from './hooks/useUserInfoStore'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import ProfileSettingMenu from './components/ProfileSettingMenu'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { formatDateWithGMT } from '$app/utils'

const { colors } = themeVars

const GlobalUserLayout: FC<PropsWithChildren<{ userType: USER_TYPE }>> = ({
  children,
  userType
}) => {
  const { userId } = useUserId(userType)

  const {
    fetchServiceUserInfoAsync,
    fetchingServiceUserInfo,
    serviceUserInfo
  } = useGlobalServiceUserService()

  const updateUserInfo = useUserInfoStore(state => state.updateUserInfo)

  const joinOnAt = useUserInfoStore(state => state.userInfo?.created_at)

  useMount(() => {
    if (!userId) return
    fetchServiceUserInfoAsync({ userId }).then(({ data }) => {
      updateUserInfo(data)
    })
  })

  const loading = fetchingServiceUserInfo || !serviceUserInfo

  if (loading) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_APP_USERS}
        data={[{ name: 'User Details' }]}
      />

      <section className="flex flex-col flex-1">
        <LayoutWithBackground style={{ position: 'fixed', height: 216 }} />

        <aside className={SidebarStyle}>
          <ProfileInfo />

          <article className="flex-1 flex flex-col">
            <article className="flex flex-col gap-1 p-4">
              <p className={title.three}>PROFILE SETTING</p>
              <p className={description}>
                Your setting can be editable on CARRO SSO.
              </p>
            </article>

            <ProfileSettingMenu />
          </article>

          <article
            className="flex p-4 border-t"
            style={{ borderColor: colors.neutral[10] }}
          >
            <article className="flex flex-col gap-0.5">
              <p
                className={footnote.two}
                style={{ color: colors.text.disabled }}
              >
                Joined ON
              </p>
              <p className={title.three}>{formatDateWithGMT(joinOnAt)}</p>
            </article>
          </article>
        </aside>

        <main className={MainContainerStyle}>
          <article className={CommonContainerStyle}>{children}</article>
        </main>
      </section>
    </>
  )
}

export default GlobalUserLayout
