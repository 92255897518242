import Breadcrumb from '$components/Breadcrumb/v2'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import Loading from '$components/Loading'
import { useCallback } from 'react'
import UserRoles from '../Create/Form/UserRoles'
import FooterAction from '$components/FooterAction'
import { Button, Spinner } from '@genie-fintech/ui/components'
import RedirectPrompt from '$blocks/RedirectPrompt'
import { z } from 'zod'

const schema = z.object({
  roles: z.number().array().min(1, 'Please assign one role at least!')
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  roles: []
}

const AppUserAssignRoles = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    fetchAppUserDetailAsync,
    fetchingAppUserDetail,
    attachRoleToUserAsync,
    attachingRoleToUser
  } = useAppUserService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetailAsync({
      application_id: appId,
      user_id: userId
    }).then(({ data }) => {
      const modifiedData: TFormValues = {
        roles: data.roles.map(v => v.id)
      }

      reset(modifiedData)
    })
  })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit(({ roles }: TFormValues) => {
    if (!appId || !userId) return

    return attachRoleToUserAsync(appId, userId, roles).then(onSuccess)
  })

  if (fetchingAppUserDetail) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <form
        className="flex flex-col w-full max-w-[1056px] mx-auto gap-2"
        onSubmit={onSubmit}
      >
        <UserRoles
          roles={selectedRoles}
          onChange={replace}
          errorMessage={errors.roles?.message}
          appId={appId}
        />

        <FooterAction isFullPage>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={attachingRoleToUser}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!isDirty || attachingRoleToUser}>
              {attachingRoleToUser && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        isDirty={isDirty}
        isValid={isValid}
        type="edit"
        loading={attachingRoleToUser}
        onConfirm={onSubmit}
      />
    </>
  )
}

export default AppUserAssignRoles
