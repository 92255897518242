import { copy } from '$app/utils'
import { useBoolean } from 'ahooks'
import { useCallback } from 'react'

export const useCopy = (value: string) => {
  const [isCopied, { setTrue, setFalse }] = useBoolean()

  const onCopy = useCallback(() => {
    copy(value)
    setTrue()
  }, [value, setTrue])

  const onBlur = useCallback(() => {
    setFalse()
  }, [setFalse])

  return {
    isCopied,
    onCopy,
    onBlur
  }
}
