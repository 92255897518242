import ModKey from '$components/ModKey'
import { useFocusEventLister } from '$hooks/actions'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import { useRef } from 'react'
import { postFixTextStyle } from './styles.css'

interface ISearchBoxProps {
  searchValue: string
  onChangeSearchValue: (searchValue: string) => void
  placeholder?: string
}

const SearchBox = ({
  searchValue,
  onChangeSearchValue,
  placeholder = 'Search'
}: ISearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  return (
    <BaseText
      inputRef={inputRef}
      fullWidth
      affix={{
        pre: <Icon namespace="Search" width={16} />,
        post: searchValue ? (
          <button onClick={() => onChangeSearchValue('')}>
            <Icon namespace="Cross" width={18} color="text.light" />
          </button>
        ) : (
          <p className={postFixTextStyle}>
            <ModKey /> K
          </p>
        )
      }}
      inputProps={{
        type: 'text',
        value: searchValue,
        onChange: e => onChangeSearchValue(e.currentTarget.value),
        placeholder
      }}
    />
  )
}

export default SearchBox
