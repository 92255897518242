import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useToggle } from 'ahooks'
import { PenLine } from 'lucide-react'
import { commonFieldBlock } from './styles.css'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { cn, getDateOfBirth, getPhone } from '$app/utils'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { BaseText } from '@genie-fintech/ui/components/fields'
import Select from '$components/Select'
import { useCountryStore } from '$hooks/stores'
import { GENDER_OPTIONS } from '$app/constants'
import {
  INITIAL_DEFAULT_VALUES,
  LABEL,
  outlineNeutralButtonVariant,
  schema,
  TFormValues
} from './constants'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { useGlobalServiceUserService } from '$hooks/services'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'

const { colors } = themeVars

const Info = ({ label, value }: { label: string; value?: string }) => {
  return (
    <article className={cn('flex-1 flex flex-col gap-0.5')}>
      <p className={footnote.two} style={{ color: colors.neutral[60] }}>
        {label}
      </p>
      <p className={title.three} style={{ color: colors.text.light }}>
        {value || '-'}
      </p>
    </article>
  )
}

const GlobalServiceUserDetail = () => {
  const [isEdit, { toggle }] = useToggle()

  const { updateServiceUserAsync, updatingServiceUser } =
    useGlobalServiceUserService()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const { userInfo, updateUserInfo } = useUserInfoStore(
    useShallow(state => ({
      userInfo: state.userInfo,
      updateUserInfo: state.updateUserInfo
    }))
  )

  const {
    id,
    name,
    email,
    phone_code,
    phone_no,
    date_of_birth,
    gender,
    address
  } = userInfo ?? {}

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty }
  } = methods

  const _gender = useMemo(
    () =>
      GENDER_OPTIONS.find(d => d.value === gender) ?? {
        label: '',
        value: ''
      },
    [gender]
  )

  const onEdit = useCallback(() => {
    reset({
      name: name ?? '',
      email: email ?? '',
      phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
      phone_no: phone_no ?? '',
      date_of_birth: date_of_birth ?? '',
      gender: _gender,
      address: address ?? ''
    })

    toggle()
  }, [
    reset,
    name,
    email,
    phone_code,
    phone_no,
    date_of_birth,
    _gender,
    address,
    toggle
  ])

  const onCancel = useCallback(() => {
    toggle()
    reset(INITIAL_DEFAULT_VALUES)
  }, [toggle, reset])

  const onSave = useCallback(
    async (values: TFormValues) => {
      if (!id) return

      const { gender, phone_code, ...rest } = values

      const payload = {
        phone_code: phone_code.value,
        gender: gender.value,
        ...rest
      }

      await updateServiceUserAsync(id, payload)
        .then(() => {
          toast.success(`Successfully Updated!`)
          if (!userInfo) return
          updateUserInfo({ ...userInfo, ...payload })
        })
        .then(toggle)
    },
    [id, userInfo, updateUserInfo, updateServiceUserAsync, toggle]
  )

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title="USER DETAILS"
        desc="Manage and review user accounts for efficient system administration."
      />
      <form className={mainSubContainerStyle} onSubmit={handleSubmit(onSave)}>
        <header className="flex items-center gap-3">
          <p
            className={title.six}
            style={{ color: colors.text.light, flex: 1 }}
          >
            Some of the user informations can be editable.
          </p>

          {isEdit && (
            <>
              <Button
                styleVariants={outlineNeutralButtonVariant}
                onClick={onCancel}
                disabled={updatingServiceUser}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                styleVariants={{ size: 'small' }}
                disabled={updatingServiceUser || !isDirty}
              >
                {updatingServiceUser && <Spinner />}
                Save Changes
              </Button>
            </>
          )}

          {!isEdit && (
            <Button
              styleVariants={outlineNeutralButtonVariant}
              onClick={onEdit}
            >
              <PenLine size={16} />
              Edit
            </Button>
          )}
        </header>

        <main className={commonFieldBlock}>
          {!isEdit && (
            <EnterTransitionBlock className="flex-1 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-8">
              <Info label={LABEL.USERNAME} value={name} />
              <Info label={LABEL.EMAIL} value={email} />
              <Info
                label={LABEL.PHONE}
                value={getPhone(phone_code, phone_no)}
              />
              <Info label={LABEL.DOB} value={getDateOfBirth(date_of_birth)} />
              <Info label={LABEL.GENDER} value={_gender.label} />
              <Info label={LABEL.ADDRESS} value={address} />
            </EnterTransitionBlock>
          )}

          {isEdit && (
            <EnterTransitionBlock className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <article>
                <BaseHookFieldText
                  name="name"
                  control={control}
                  label={LABEL.USERNAME}
                  required
                />
              </article>

              <article>
                <BaseText
                  label={LABEL.EMAIL}
                  disabled
                  inputProps={{ value: email, readOnly: true }}
                />
              </article>

              <article className="relative">
                <BaseHookFieldText
                  name="phone_no"
                  control={control}
                  label={LABEL.PHONE}
                  inputProps={{ className: 'pl-[100px]' }}
                />

                <article className="absolute bottom-0 left-0 w-[100px]">
                  <Controller
                    name="phone_code"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          value={field.value}
                          onChange={field.onChange}
                          options={phoneOptions}
                          type="sub"
                        />
                      )
                    }}
                  />
                </article>
              </article>

              <article>
                <BaseHookFieldText
                  control={control}
                  name="date_of_birth"
                  label={LABEL.DOB}
                  inputProps={{ type: 'date' }}
                />
              </article>

              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-2">
                      <label className="text-sm font-medium">Gender</label>
                      <Select
                        {...field}
                        options={GENDER_OPTIONS}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <article>
                <Textarea
                  name="address"
                  control={control}
                  label={LABEL.ADDRESS}
                />
              </article>
            </EnterTransitionBlock>
          )}
        </main>
      </form>
    </EnterTransitionBlock>
  )
}

export default GlobalServiceUserDetail
