import { cn } from '$app/utils'
import { defaultBackground, shadow } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { FC, PropsWithChildren } from 'react'

const { colors } = themeVars

const FooterAction: FC<PropsWithChildren<{ isFullPage: boolean }>> = ({
  children,
  isFullPage
}) => {
  return (
    <footer
      className={cn(
        'fixed bottom-0 inset-x-0 p-2 border-t',
        shadow.small,
        defaultBackground,
        !isFullPage && 'ml-[236px]'
      )}
      style={{ borderTop: `1px solid ${colors.neutral[10]}` }}
    >
      {children}
    </footer>
  )
}

export default FooterAction
