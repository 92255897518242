import { FC, PropsWithChildren } from 'react'
import Sidebar from './Sidebar'
import { MainContainerStyle } from './styles.css'

const LayoutWithSidebar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section>
      <Sidebar />

      <article className={MainContainerStyle}>
        <article className="flex w-full max-w-[1056px] mx-auto px-2 mb-[60px]">
          {children}
        </article>
      </article>
    </section>
  )
}

export default LayoutWithSidebar
