import CaptainAppLogo from '$assets/captain_app_logo.svg'
import CarroAppLogo from '$assets/carro_app_logo.svg'
import GenieAppLogo from '$assets/genie_app_logo.svg'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

export const APP_TOKEN_KEY = 'app-token'

export const TOOLTIP_ID = 'r-tooltip'

export const ACL_OPTIONS = [
  { value: 'role_based', label: 'ROLE BASED (Genie)' },
  { value: 'permission_based', label: 'PERMISSION BASED (Carro)' }
]

export const BREADCRUMB_BAR_ID = 'breadcrumb-bar'

export const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
]

export const USER_STATUS_TABS = [
  { label: 'ACTIVE', value: 'active' },
  { label: 'INACTIVE', value: 'inactive' }
]

export const APP_ENVIRONMENT_OPTIONS = [
  {
    key: 1,
    value: 'development'
  },
  {
    key: 2,
    value: 'local'
  },
  {
    key: 3,
    value: 'staging'
  },
  {
    key: 4,
    value: 'uat'
  },
  {
    key: 5,
    value: 'production'
  }
]

export const APP_BRANDS = [
  {
    name: 'captain',
    Icon: CaptainAppLogo,
    backgroundColor: colors.alphaPrimary[1]
  },
  {
    name: 'carro',
    Icon: CarroAppLogo,
    backgroundColor: 'hsla(18,100%,50%,0.1)'
  },
  {
    name: 'genie',
    Icon: GenieAppLogo,
    backgroundColor: colors.alphaPrimary[1]
  }
]

export const MIN_PWD_LENGTH = 6

export const MAX_PWD_LENGTH = 30

export const ALLOWED_SPECIAL_CHARACTERS = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*'
]

export const DATE_WITH_GMT_FORMAT_CODE = 'dd MMM yyyy, hh:mma z'

// TODO: need to check each permission implementation detail later
export enum PERMISSIONS {
  // APPLICATION
  LIST_APPLICATION = 'application:list',
  CREATE_APPLICATION = 'application:create',
  VIEW_APPLICATION_BASIC = 'application:view-basic',
  EDIT_APPLICATION_BASIC = 'application:edit-basic',
  VIEW_APPLICATION_ADVANCED = 'application:view-advanced',
  EDIT_APPLICATION_ADVANCED = 'application:edit-advanced',
  VIEW_APPLICATION_LAUNCHPAD = 'application:view-launchpad',
  EDIT_APPLICATION_LAUNCHPAD = 'application:edit-launchpad',
  VIEW_APPLICATION_GROUP = 'application:group:view',
  ADD_APPLICATION_GROUP = 'application:group:add',
  EDIT_APPLICATION_GROUP = 'application:group:edit',
  DELETE_APPLICATION_GROUP = 'application:group:delete',
  VIEW_APPLICATION_USER = 'application:user:view',
  ADD_APPLICATION_USER = 'application:user:add',
  IMPORT_APPLICATION_USER = 'application:user:import',
  EDIT_APPLICATION_USER = 'application:user:edit',
  REMOVE_APPLICATION_USER = 'application:user:remove',
  BAN_APPLICATION_USER = 'application:user:ban',
  VIEW_APPLICATION_ROLE = 'application:role:view',
  ADD_APPLICATION_ROLE = 'application:role:add',
  IMPORT_APPLICATION_ROLE = 'application:role:import',
  EDIT_APPLICATION_ROLE = 'application:role:edit',
  DELETE_APPLICATION_ROLE = 'application:role-delete',
  VIEW_APPLICATION_PERMISSION = 'application:permission:view',
  ADD_APPLICATION_PERMISSION = 'application:permission:add',
  IMPORT_APPLICATION_PERMISSION = 'application:permission:import',
  EDIT_APPLICATION_PERMISSION = 'application:permission:edit',
  DELETE_APPLICATION_PERMISSION = 'application:permission:delete',
  DELETE_APPLICATION = 'application:delete',
  PUBLISH_APPLICATION = 'application:publish',

  // GLOBAL SETTING
  GLOBAL_SETTING_EDIT_PASSWORD_POLICY = 'global:edit-password-policy',

  // COUNTRY
  CREATE_COUNTRY = 'country:create',
  VIEW_COUNTRY = 'country:view',
  EDIT_COUNTRY = 'country:edit',
  DELETE_COUNTRY = 'country:delete',

  // USER
  LIST_USER = 'user:list',
  VIEW_USER = 'user:view',
  BAN_USER = 'user:ban',
  EDIT_USER = 'user:edit',
  EDIT_USER_PASSWORD_SETTING = 'user:setting:edit',

  // DASHBOARD USER
  LIST_ADMIN = 'admin:list',
  VIEW_ADMIN = 'admin:view',
  EDIT_ADMIN = 'admin:edit',
  DELETE_ADMIN = 'admin:delete',
  BAN_ADMIN = 'admin:ban',
  CREATE_ADMIN = 'admin:create',
  ADD_ADMIN_APPS = 'admin:add-user-apps',
  MANAGE_SERVICE_ACCOUNTS = 'admin:manage-service',
  MANAGE_ADMIN_ACCOUNTS = 'admin:manage',
  MANGE_SERVICE_ACCOUNT_PASSWORDS = 'admin:manage-service-passwords'
}
