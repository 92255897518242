import Breadcrumb from '$components/Breadcrumb/v2'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService, useDeleteService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import UserInfo from './UserInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import { Trash2 } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'

const AppUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const { user, fetchAppUserDetail, fetchingAppUserDetail } =
    useAppUserService()

  const { terminateAppUserAsync, terminatingAppUser } = useDeleteService()

  const isUserActive = user?.status === 'active'

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetail({
      application_id: appId,
      user_id: userId
    })
  })

  const onTerminateUser = useCallback(() => {
    if (!appId || !userId) return
    terminateAppUserAsync({ appId, userId })
  }, [appId, userId, terminateAppUserAsync])

  if (fetchingAppUserDetail || !user) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      />

      <article className="flex flex-col gap-2 flex-1">
        <UserInfo user={user} />

        {isUserActive && (
          <DangerZone type="app_user">
            <DeleteDoubleConfirmPopUp
              item={{ type: 'app_user', name: user.name }}
              onExecute={onTerminateUser}
              loading={terminatingAppUser}
            >
              <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                Terminate User
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        )}
      </article>
    </>
  )
}

export default AppUserDetail
